import '../css/CourseDetail.css'
import { useState, useEffect } from 'react'
import "../css/MainNav.css";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import img_logo from '../img/serpent.png'
import img_plus from './img/plus.png'
import MainNav from '../components/MainNav';
import Content from '../components/Content'
import cancel from '../img/cancel.png'
import Footer from './Footer';
import ReactPlayer from 'react-player'
import db from '../firebase'
import {doc, getDoc } from 'firebase/firestore'
import { map } from '@firebase/util';

function CourseDetail() {
    let [navVisible, setNavVisible] = useState(false)
    let [slug, setSlug] = useState("")
    const [videoFilePath, setVideoFilePath] = useState(null);
    let [posts, setPosts] = useState<any[]>([])
    let [load, setLoad] = useState(false)
    let [sections, setSections] = useState<any[]>([])
    let [sectionHeaders, setSectionHeaders] = useState<string[]>([])
    let [resourceStatus, setResourceStatus] = useState("hide")
    let [currentResource, setCurrentResource] = useState<any[]>([])
    let [courseContent, setCourseContent] = useState("Preview")

    let [videoUrl, setVideoUrl] = useState("")
    let [isEnded, setIsEnded] = useState(false)
    let [currentLessonI, setCurrentLessonI] = useState(0)
    let [currentResourceA, setCurrentResourceA] = useState<any[]>([])
    let [currentTitle, setCurrentTitle] = useState("Title: ")
    let [hasSectionResource, setHasSectionResource] = useState(false)
    let [currentSectionResource, setCurrentSectionResource ] = useState<any[]>()
    let [currentResourceTitle, setCurrentResourceTitle] = useState("Resource 1")
    let [currentResourceUrl, setCurrentResourceUrl] = useState("/")

    /*
   {
    sections: 3,
    section_1[{title:"", type: video, html, }, ... ]
    section_1_title: ""
   }
    */
 
    useEffect(() => {
        let currentpathName = window.location.pathname;
        console.log("pathname: "+ currentpathName)
        // /course/1
        slug = currentpathName.substring(3, currentpathName.indexOf("/class"))
        console.log("slug: "+slug)
        setSlug(slug)
        const getPosts = async (fullAccess: boolean) => {

          
          const docRef = doc(db, "course", slug);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            console.log("Document data:", docSnap.data());

            console.log(docSnap.data().sections)
            let sectionsI: number = Number(docSnap.data().sections)
            setVideoUrl(docSnap.data().video_url)
            setCurrentTitle(docSnap.data().section_1_title)
            let theDoc: any = docSnap.data()
            let allSectionsA:any = []
            let sectionA:any = []
            let numI = 0
            let class_num:number = 0
            if (!fullAccess){
                sectionsI = 2
            }

            for (let i =1; i<= sectionsI; i++){
               
                let subsectionsI:number = theDoc["section_"+i].length
               let title = theDoc['section_'+i+'_title'];
               sectionHeaders.push(title)
                sectionA.push({class_num, num:numI,title:title, type:"header", url: theDoc["video_url"], class:"active", displayImg: "blockD", nested:"nested3"})
               numI+=1;
                for (let inn = 0; inn <subsectionsI; inn++){
                  //console.log("is ora currentResource?")
                  let arrCR: any = []

                  if (theDoc["section_"+i][inn].external_resources>=1){
                    let objRes = {lesson: i,url: theDoc["section_"+i][inn].ext_resource_1_url, 
                  title: theDoc["section_"+i][inn].ext_resource_1_title }
                  arrCR.push(objRes)
                  console.log("sec_title"+theDoc["section_"+i][inn].ext_resource_1_title )
                  console.log("ora currentResource")

                  }
                  console.log("arrCR"+ arrCR)
                  setCurrentResource(arrCR)

                console.log(theDoc["section_"+i][inn].title)
                let t = theDoc["section_"+i][inn].title
                let u = theDoc["section_"+i][inn].url
                let type = theDoc["section_"+i][inn].type
                if (arrCR.length==1){
                  sectionA.push({external_resources:1, res_title:arrCR[0].title,
                    res_url:arrCR[0].url
                     ,class_num, num:numI,title: t, url: u, type, class:"inactive", displayImg: "noneD", nested:"nested2"})

                }else{
                  sectionA.push({class_num, num:numI,title: t, url: u, type, class:"inactive", displayImg: "noneD", nested:"nested2"})

                }
                numI+=1
            }
                allSectionsA.push(sectionA)

               
            class_num+=1
            }

             setSections(sectionA)
             sections.map(e=>{
                console.log(e)
             })
        

          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
         

            return posts


      
            

                    }

                    const getIfAccess = async (uid: string) =>{
let currentpathName = window.location.pathname;
console.log("pathname: "+ currentpathName)
// /course/1

//users/JbUG8vpfL4gZIe8nYG4GBV8xcYZ2/course/solana-blockchain-developer

slug = currentpathName.substring(3, currentpathName.indexOf("/class"))
console.log("users/"+uid+"/course/"+slug)

                        const docRef = doc(db, "users/"+uid+"/course/", slug);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data());
          if (docSnap.data().access == true){
console.log("access is true")
setCourseContent("Course Content")
getPosts(true)

          }else{
            console.log("no access")
            getPosts(false)
          }
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
          getPosts(false)
        }
                        
                    }
        if (!load){



            const auth = getAuth();
             onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
               
getIfAccess(user.uid)
                // ...
            } else {
                // User is signed out
                // ...
                getPosts(false)

            }
            });
load = true
setLoad(load)
        }else{
          console.log("loaded")
        }
      


       

    

    }, [])

   
    
    function setNavVisibleGo(){
        let isNavB = !navVisible
        setNavVisible(isNavB)

        console.log(isNavB)
    }

    function clickLesson(e: any){
let sectionArrO: any = sections[Number(e.value)];

for (let eo = 0; eo <sections.length; eo++){
    if (sections[eo].type != "header" ){
        let classS:string = "isInactive inactive"
        if (eo == Number(e.value)){
            classS = "active isActive"
            sections[eo].class = classS
            console.log(sections[eo])
        }
        else{
            sections[eo].class = classS
    
        }
    }
  setCurrentLessonI(Number([e.value]))
  setCurrentTitle(sections[Number([e.value])].title)
}

if (sectionArrO.type=="header"){
    console.log(e.value + "header, class_num: "+sectionArrO.class_num)
    hideAllExceptCurrent(e.value)
}else{
    console.log(e.value + "lesson, class_num: "+sectionArrO.class_num)
    setVideoUrl(sectionArrO.url)
    if (sections[Number(e.value)].external_resources == 1){
      setHasSectionResource(true)
      setCurrentResourceTitle(sections[Number(e.value)].res_title)
      setCurrentResourceUrl(sections[Number(e.value)].res_url)
      setCurrentSectionResource(sections[Number(e.value)])

    }else{
      setHasSectionResource(false)

    }

}
function hideAllExceptCurrent(val: any){
    

}

// function isEnded(e){
//     //check if there are more videos
//     console.log("is ended")
// }

  let handleVideoEnded = ()=>{
    console.log('Video ended!');
  };
  
//setVideoUrl(sections[currentLessonI+1].url), setCurrentLessonI(currentLessonI+1))
  //sections style={{overflowY:"scroll",height:"70%", overflowX:"hidden" }}
    }
    return ( <>
    <div className={navVisible? "main_visible2": "main_invisible"} id="main_container"><div>
    <header className={navVisible? "header_move2": "header_static"} id="header">
<div id="menu_button">
    <button className='nav_animate_text' onClick={setNavVisibleGo}>MENU</button>
<div className="icons-menu">
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>


                        </div>
</div>
<div id="brand"></div>
<img id='img_logo' src={img_logo}></img>
<p id="brand_name">Serpent</p>
<div id='bt-learn'><button className='button'>Earn Serpent!</button></div>

<div id='container'>
<div className={navVisible? "player-wrapper": "player-wrapper-large"}  >
            <ReactPlayer
            autoPlay={true}
            playing={true}
            className={navVisible? "react-player fixed-bottom": "react-player fixed-bottom"} 
            url= {videoUrl}
            width='100%'
            height='100%'
            controls = {true}
            onEnded={()=> {
   
                for (let i= 0; i<sections.length; i++){
                    // if (sections[i].type == "header" && i == currentLessonI+1){
                    //     sections[i+2].class = "isActive"

                    // }
                          if (sections[i].type != "header"){

                        sections[i].class = "isInactive inactive"
                   } 
            }
            if (sections[currentLessonI+1].type!= "header"){
                sections[currentLessonI+1].class = "isActive"
                if (currentLessonI < sections.length){
                    console.log("minor currentLessonI: "+ currentLessonI)
                    setVideoUrl(sections[currentLessonI+1].url);
                    setCurrentLessonI(currentLessonI+1);

                    console.log("minor currentLessonI: "+ currentLessonI)
                
                }
            }
            else if (sections[currentLessonI+1].type== "header"){
                console.log("is header")
                sections[currentLessonI+2].class = "isActive"
                if (currentLessonI < sections.length){
                    console.log("minor currentLessonI: "+ currentLessonI)
                    setVideoUrl(sections[currentLessonI+2].url);
                    setCurrentLessonI(currentLessonI+2);

                    console.log("minor currentLessonI: "+ currentLessonI+2)
                
                }
            }
            setSections(sections)
            //setVideoUrl(sections[currentLessonI+1].url);
            //setCurrentLessonI(currentLessonI+1);
console.log("ended")


        }    
    }
            

            />
        </div>
        <div id="resources">
        <div>
  
        <h3 className='title'>{currentTitle}</h3>
        <div className={hasSectionResource? "show_resource":"hide_resource"} id="external_resources">
        <h3  >Resources: </h3>
        
        <a className='title_url' href={currentResourceUrl}><p>{currentResourceTitle}</p></a>
     
        </div>

        </div>
        </div>
</div>

<Footer />

    </header>
    </div>    </div>
    <nav className={navVisible? "show_nav":"hide_nav"} id="mainNav">
        <div id="sub_menu_top">
<div className="left"><a className='language' href="#">English</a></div>
<button className="right1" onClick={setNavVisibleGo}>Close</button>
<button className="right2" onClick={setNavVisibleGo}><img className='close' src={cancel}/></button>

        </div>
         <ul className={navVisible? "show_nav":"hide_nav"} id="sub_menu">
         <hr className="menuhr">

         </hr>

            <li  className='inactive'><span className='nested'>{courseContent}</span></li>
            <hr className="menuhr"></hr>
            <div
            

            
            id="sections">
            {sections.map(d =>(<div className='' key={d.num}>
                <li value={d.num} onClick={e =>clickLesson(e.target)}  className={d.class}>
                    <img className={'img_plus '+d.displayImg+''} height="25px" width="25px" src={img_plus} />
                    <button  className='nested'><span  className={d.nested}>{d.title}</span>
            </button></li>
            <hr className="menuhr"></hr>
            </div>
            ))}
          </div>







         </ul>
         <div id="sub_menu_bottom">Earn Serpent!</div>
         <div id="sub_menu_earn">
<label className="switch">
  <input type="checkbox" />
  <span className="slider round"></span>
</label></div>
        

    </nav>
</>
     );
}

export default CourseDetail;