import {Link} from 'react-router-dom'


import { getAuth, onAuthStateChanged, signInAnonymously } from "firebase/auth";
import { setDoc, FieldValue, serverTimestamp } from "firebase/firestore"; 


import {useEffect, useState} from 'react'
import { collection, query, where, getDoc, DocumentData, doc } from "firebase/firestore";
import db from "../firebase"
import '../css/Course.css'
import playImg from './img/play-button-white.png'

//new imports 
import { WalletAdapterNetwork,WalletNotConnectedError } from '@solana/wallet-adapter-base';
import { ConnectionProvider, WalletProvider, useConnection, useWallet } from '@solana/wallet-adapter-react';
import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import {
    GlowWalletAdapter,
    PhantomWalletAdapter,
    SlopeWalletAdapter,
    SolflareWalletAdapter,
    TorusWalletAdapter,
    LedgerWalletAdapter,
    SolletExtensionWalletAdapter,
    SolletWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import ReactDOM from 'react-dom';
import validNftsA from './hashlist.json';

import { Connection } from '@metaplex/js'; 

import { Metadata } from '@metaplex-foundation/mpl-token-metadata';


import { clusterApiUrl, PublicKey, LAMPORTS_PER_SOL } from '@solana/web3.js';
import React, { FC, ReactNode, useMemo, useCallback } from 'react';


import {TOKEN_PROGRAM_ID} from '@solana/spl-token';
require('@solana/wallet-adapter-react-ui/styles.css');

let tokensInWallet:any[] = []
let validTokensInWallet: string[] = []

const App: FC = () => {
  return (
      <Context>
          <Content />
      </Context>
  );
};
export default App;


const Context: FC<{ children: ReactNode }> = ({ children }) => {
    // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
    const network = WalletAdapterNetwork.Mainnet;

    // You can also provide a custom RPC endpoint.
    const endpoint = useMemo(() => clusterApiUrl(network), [network]);

    // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
    // Only the wallets you configure here will be compiled into your application, and only the dependencies
    // of wallets that your users connect to will be loaded.
    const wallets = useMemo(
        () => [
            new PhantomWalletAdapter(),
            new GlowWalletAdapter(),
            new SlopeWalletAdapter(),
            new SolflareWalletAdapter({ network }),
            new TorusWalletAdapter(),
            new LedgerWalletAdapter(),
            new SolletExtensionWalletAdapter(),
            new SolletWalletAdapter(),
        ],
        [network]
    );

    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>{children}</WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    );
};

const Content: FC = () => {

let [nftsInWallet, setNftsinWallet] = useState(0)
let [statusHolder, setStatusHolder] = useState(false)
let [validNFTsI, setValidNFTsI] = useState(0)

const connection = new Connection("https://api.mainnet-beta.solana.com");

    
    //getTokenAccountsByOwner(publicKey,)

    let elements:any = []
    let element;
    
  
   

    
    async function getAccountMetaData(mintAddress, amountI, numberI){
       (async () => {
        let mintPubkey = new PublicKey(mintAddress);
        let tokenmetaPubkey = await Metadata.getPDA(mintPubkey);
      
        const tokenmeta:any = await Metadata.load(connection, tokenmetaPubkey);
        //console.log(tokenmeta);
       // console.log(tokenmeta.data.data["name"])
       // nftsInWallet.push({name: tokenmeta.data.data["name"], uri: tokenmeta.data.data["uri"]})
        //console.log("nfts: "+nftsInWallet)
       tokensInWallet[numberI].name = tokenmeta.data.data["name"]
       tokensInWallet[numberI].uri = tokenmeta.data.data["uri"]
       console.log("uri"+tokenmeta.data.data["uri"])
       console.log()
       // console.log(tokenmeta.data.data["uri"])
       //tokensInWallet.push({mint:mintAddress })
    
       // UpdateTheUI(mintAddress, tokenmeta.data.data["uri"], tokenmeta.data.data["name"], numberI)
      })();
    }
    
    const { publicKey, sendTransaction } = useWallet();

    async function getIfValidNfts() {

      const connection = new Connection("mainnet-beta");
      if (!publicKey) throw new WalletNotConnectedError();
      connection.getBalance(publicKey).then((bal) => {
          console.log("lamports: "+bal/LAMPORTS_PER_SOL);


      });

let publicKeyString = publicKey;
let validNftsArray = JSON.parse(JSON.stringify(validNftsA));
        const ownerPublickey = publicKey.toBase58();
        const nftsmetadata = await Metadata.findDataByOwner(connection, publicKeyString);
      
        console.log(nftsmetadata.length)
      
        let counter: number = nftsmetadata.length;
        console.log("counter: "+counter);
      
        let counterI= 0;
        let nftsThatAreValid: string[]= [];
        for (var i in nftsmetadata){
        //console.log(nftsmetadata[i].mint);

        for (var ao in validNftsArray){
          if (nftsmetadata[i].mint == validNftsArray[ao]){
            console.log("✅"+nftsmetadata[i].mint);
            nftsThatAreValid.push(nftsmetadata[i].mint+"");
          }
        } 


        counterI+=1;
        }
        if(nftsThatAreValid.length>=3){
          console.log("valid:"+nftsThatAreValid.length)
          setStatusHolder(true)
        }
      setNftsinWallet(counterI)
      let nfts_total_element;
if (nftsThatAreValid.length >3){
   nfts_total_element = <span>({"✅ "+nftsThatAreValid.length})</span>;

}else{
   nfts_total_element = <span>({nftsThatAreValid.length})</span>;

}


 await setValidNFTsI(nftsThatAreValid.length)

        await ReactDOM.render(nfts_total_element, document.getElementById("validTotalNFTs"))
    

            await signInAnonymouslyF(nftsThatAreValid.length, ownerPublickey, nftsThatAreValid) 

    
      
    }

    function signInAnonymouslyF(n: number, ownerPublickey: string, validNFTsA: string[]){
      const auth = getAuth();
      signInAnonymously(auth)
        .then(() => {
          // Signed in..
          console.log("signed")
          // if valid NFTs >3 then enter course
         if (n> 3){
          console.log(">3 valid NFTs ✅")
          // give use access
console.log('users/'+auth.currentUser?.uid+"/course/"+slug +" ,"+ownerPublickey)

const docCourseRef = doc(db, 'users/'+auth.currentUser?.uid+"/course/", slug);
setDoc(docCourseRef, { created_at: serverTimestamp(),updated_at: serverTimestamp(),access: true, solana_wallet: ownerPublickey, validNFTs: validNFTsA, validNFTsI: validNFTsA.length  }, { merge: true });


         }else{
          console.log("<3 valid NFTs")

         }
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          // ...
        });
    }

    const onClick = useCallback( async () => {
      

      if (!publicKey) throw new WalletNotConnectedError();
      connection.getBalance(publicKey).then((bal) => {
          console.log(bal/LAMPORTS_PER_SOL);

      });

      console.log(publicKey.toBase58());
      //getTheTokensOfOwner(publicKey.toBase58());
      getIfValidNfts()

  }, [publicKey, sendTransaction, connection]);

  const downloadFile = () => {
    var a = document.createElement('a');
a.href = "https://github.com/serpentacademy/Validate-Solana-NFT-Holders/raw/master/src/hello.pdf";
a.download = "hello.pdf";
document.body.appendChild(a);
a.click();
document.body.removeChild(a);

  }
   
  let [slug, setSlug] = useState("")
    let [load, setLoad] = useState(false)
    let [udemyLink, setUdemyLink] = useState("/")
    let [skillshareLink, setSkillshareLink] = useState("/")

    let [posts, setPosts] = useState<any>([])
    let [listItems, setListItems] = useState<any[]>([])
    let [learn, setLearn] = useState<string[]>([])
    let [image, setImage] = useState("")
    let [title, setTitle] = useState("")
    let [shortDescription, setShortDescription] = useState("")
    let [description, setDescription] = useState("")
    
//new add 




    useEffect(() => {

    

        let currentpathName = window.location.pathname;
        console.log("pathname: "+ currentpathName)
        // /course/1
        slug = currentpathName.substring(8, currentpathName.lastIndexOf("/"))
        console.log("slug: "+slug)
        setSlug(slug)
        const getPosts = async () => {


          const docRef = doc(db, "courses", slug);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            console.log("Document data:", docSnap.data());
            setPosts(docSnap.data());
            setLearn(docSnap.data()["learn"])
            setImage(docSnap.data().image)
            setTitle(docSnap.data().title)
            setUdemyLink(docSnap.data().udemy)
            setShortDescription(docSnap.data().prelude)
            setDescription(docSnap.data().description)
            setSkillshareLink(docSnap.data().skillshare)

          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
         

            return posts


      
            

                    }
        if (!load){
            getPosts()
load = true
setLoad(load)
        }else{
          console.log("loaded")
        }
      


       

    

    }, [])

      function checkIfValidNFT(){
        // check if user owns a valid NFT
        console.log("checking")
        window.location.href = "/c/"+slug+"/class/0/lesson/0/"
      }
      function buyTheNFTs(){
        window.location.href = "https://solsea.io/a/6148adffa71fbdd02113b013/collections/"
      }
      function goToPreview(){
        window.location.href = '/c/'+slug+'/class/0/lesson/0/'
      }

      function goToUdemy(){
        window.location.href = udemyLink
      }

    return (
               <div className="Apps">
     
      
     <>
       <div id="content" className="content" >
<ul> 



       </ul>
       <div key="courses">
       <div className='grid_1_50'><div className='inner_25'>
       <span className='course_element' >Course</span>
       <h1 className='title_course2'>{title}</h1>
       <br></br>
       <button onClick={goToPreview} className='preview_course'>Preview Course</button>
       <br></br>
       <span className='course_element' >Prelude</span>
       <h3 className='prelude2'>{shortDescription}</h3>
       <div className='course_nodes'>
       <span className='course_element_title' >You'll learn!</span><br></br>

       {learn.map(d => (<li className='learn_list'  key={d}> {d}</li>))} 
       </div>
<br></br>
       <div className='course_nodes'>
       <span className='course_element_title' >Description</span><br></br>

       <div className='course_element_description' dangerouslySetInnerHTML={{ __html: description }} />

       </div>


        </div> 
</div>
       <div className='grid_2_25'>  
       
       <div onClick={goToPreview} className='course_image' id="frame" style={{backgroundImage: `url(${image})`}}>
       <img width="75px" id="playImg" src={playImg}></img>  
       </div>
<div> 
<div id="wallet">
  <WalletMultiButton />
  <br />
</div>
<div id='instruction'>Check if I own valid NFTs</div>

  <button onClick={onClick} className='nft_access'>NFT Access Check</button>
</div>

<div  >
  <button className='buy_udemy_btn' onClick={goToUdemy} >Buy on Udemy</button>
</div>

<div >
<a className='buy' href={skillshareLink}><button  className='buy_skillshare_btn'>Buy on Skillshare</button></a>
</div>


 
</div>
<div className='grid_3_25'>   


  <div  id='nfts'>
  <br></br>    
   <br></br>  <h5>NFTs <br></br>in wallet <span >{nftsInWallet}</span></h5>


   <h5>Serpent<br></br> Academy  <span id='validTotalNFTs'></span></h5>
<p>Valid NFTs</p>
<button id='goToCourse' onClick={checkIfValidNFT}  disabled={!statusHolder} >Go To Course</button><br/><br/>
<button id='buyNFTs' onClick={buyTheNFTs}  disabled={!statusHolder} >Buy NFTs</button>
<div className='afterDiv'><br /><br /><br /><br /><br /></div>
 </div>




</div>
       </div>

       </div>
      </>










    
   
  
    </div>

    );
};

















    

  
//       {learn.map(d => (<li  key={d}> {d}</li>))} 
