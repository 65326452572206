// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getFirestore, collection} from "firebase/firestore"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAysl6F_R-EQ8Oua9R5ZUBK26ZV5csJGkw",
  authDomain: "serpentv3.firebaseapp.com",
  projectId: "serpentv3",
  storageBucket: "serpentv3.appspot.com",
  messagingSenderId: "223514013238",
  appId: "1:223514013238:web:6b49d9dc459b697bf87297",
  measurementId: "G-F66TZ1T02N"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);
export default getFirestore(app)