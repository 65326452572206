
import '../css/About.css'
function About() {
    return ( <>
      <div className="grid-item about"><div className="grid-item">About</div>
      <h2>Serpent Academy Facts</h2>
      <ul>
        <li>
          Serpent Tokens can be earned by students and teachers. **Coming soon
        </li>
        <li>
          I created Serpent Academy because I struggle because of the money, while studying robotics. 
        </li>
        <li>Learn to Earn (Earn Serpent Tokens)</li>
        <li>Fair play, fair launch. Education as a Human Right. Science is a Human Right!</li>
      </ul>
      <p><a href="https://serpent.academy/white-paper.pdf">Read our Alpha White Paper</a></p>
      </div>

    </> );
}

export default About;