import "../css/Content.css"
function Content() {
    return (<><div id="content" className="content">
       <div className="grid-container-3">
  <div className="grid-item">1</div>
  <div className="grid-item">2</div>
  <div className="grid-item">3</div>  
  <div className="grid-item">4</div>
  <div className="grid-item">5</div>
  <div className="grid-item">6</div>  
  <div className="grid-item">7</div>
  <div className="grid-item">8</div>
  <div className="grid-item">9</div> 
</div>
    </div>
    </>  );
}

export default Content;