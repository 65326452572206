import '../css/Header.css'
import "../css/MainNav.css";
import img_logo from '../img/serpent.png'
import MainNav from '../components/MainNav';
import Content from '../components/Content'
import cancel from '../img/cancel.png'
import CoursesContent from './CoursesContent';
import Footer from './Footer';
import db from '../firebase'

import {query, collection, where, doc, getDocs} from 'firebase/firestore'
import React, {useState, useEffect} from 'react'
function Header() {

   




    let [navVisible, setNavVisible] = useState(false)
    function setNavVisibleGo(){
        let isNavB = !navVisible
        setNavVisible(isNavB)

        console.log(isNavB)
    }
    return ( <>
    <div className={navVisible? "main_visible": "main_invisible"} id="main_container"><div>
    <header className={navVisible? "header_move": "header_static"} id="header">
<div id="menu_button">
    <button className='nav_animate_text' onClick={setNavVisibleGo}>MENU</button>
<div className="icons-menu">
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>


                        </div>
</div>
<div id="brand"></div>
<img id='img_logo' src={img_logo}></img>
<p id="brand_name">Serpent</p>
<div id='bt-learn'><button className='button'>Earn Serpent!</button></div>
<CoursesContent />
<Footer />
    </header>
    
    </div>    </div>
    <nav className={navVisible? "show_nav":"hide_nav"} id="mainNav">
        <div id="sub_menu_top">
<div className="left"><a className='language' href="#">English</a></div>
<button className="right1" onClick={setNavVisibleGo}>Close</button>
<button className="right2" onClick={setNavVisibleGo}><img className='close' src={cancel}/></button>

        </div>
         <ul className={navVisible? "show_nav":"hide_nav"} id="sub_menu">
         <hr className="menuhr"></hr>

            <li className='inactive'><span className='nested'>Learn + Earn</span></li>
            <hr className="menuhr"></hr>
            <li className='active'><span className='nested'>Courses</span></li>
            <hr className="menuhr"></hr>

            <li className='inactive'><span className='nested'>Classes</span></li>
            <hr className="menuhr"></hr>

            <li className='inactive'><span className='nested'>Lessons</span></li>
            <hr className="menuhr"></hr>

            <li className='inactive'><span className='nested'>White Paper</span></li>
            <hr className="menuhr"></hr>

            <li className='inactive'><span className='nested'>Docs</span></li>
            <hr className="menuhr"></hr>

            <li className='inactive'><span className='nested'>Road Map</span></li>
            <hr className="menuhr"></hr>



         </ul>
         <div id="sub_menu_bottom">Earn Serpent!</div>
         <div id="sub_menu_earn">
<label className="switch">
  <input type="checkbox" />
  <span className="slider round"></span>
</label></div>
        

    </nav>
</>
     );
}

export default Header;