import React from 'react';
import logo from './logo.svg';
import './App.css';
import {Routes, Route, Link} from "react-router-dom"
import Header from './components/Header';
import MainNav from './components/MainNav';
import Courses from './components/Courses'
import Course from './components/Course'
import Footer from './components/Footer';
import About from './components/About';
import CourseDetail from './components/CourseDetail';

function App() {
  return (
    <div className="App">
      <Routes>
      <Route path="/" element={<Courses />}/>


        <Route path='/courses' element={<Courses />}/>
        <Route path='/course/*' element={<Course />}/>
        <Route path='/c/*' element={<CourseDetail />}/>

        <Route path="/header" element={<Header />}/>



      </Routes>
   
    
    </div>
  );
}

export default App;
