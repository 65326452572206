import About from "./About";
import db from '../firebase'
import '../css/Courses.css'

import {query, collection, where, doc, getDocs} from 'firebase/firestore'
import React, {useState, useEffect} from 'react'


function CoursesContent() {
    let [coursesDocs, setCoursesDocs] = useState<any[]>([])
    let [minCourse, setMinCourse] = useState(0)
    let [loaded, setLoaded] = useState(false)
    let [maxCourse, setMaxCourse] = useState(8)
  
     // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
let theLoaded = true


    async function getCourses(){
      const q = query(collection(db, "courses"), where("number", "<=", maxCourse));
      if (loaded){
        return
    }else{
        console.log("loaded")
        loaded = theLoaded
        
    }
const querySnapshot = await getDocs(q);
querySnapshot.forEach((doc) => {
  // doc.data() is never undefined for query doc snapshots
  console.log(doc.id, " => ", doc.data());
   // Update the document title using the browser API
   return setCoursesDocs(querySnapshot.docs.map((doc)=> ({...doc.data(), id:doc.id}))) 
});
    }

    getCourses()
    
  }, []);
    return (<>
    <div id="content" className="content">

    <div className="grid-container-1">
  <div className="grid-item"><h1 className="headline_courses">Our courses</h1></div>
  
</div>

       <div className="grid-container-2">
        {coursesDocs.map(d =>
              <div className="grid-item">
              
              <div className="card"><a href={"/course/"+d.slug+"/"}>
  <img width="100%" src={d.image} alt="Avatar"  />
  <div className="container title_course">
    <div className="topContainer">
    <span className="students">Students <b>{d.students}</b></span> 
    <span className="time"> {d.time}</span> 

    </div>

    <h4><b>{d.title}</b></h4> 
    <p>{d.author}</p> 
  </div>
  </a>
</div>
              
              </div>
              )}
        
</div>
<About />
    </div>
    </>  );
}

export default CoursesContent;