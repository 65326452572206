import "../css/Footer.css"
function Footer() {
    return (<>
    
    <div className="footer">
  <p>Working hard in a web3 academy. To access courses buy 3 Official NFTs here - 
    <a href="https://solsea.io/a/6148adffa71fbdd02113b013">Get NFTs</a>
  </p>
</div>

    </>  );
}

export default Footer;